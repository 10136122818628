@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,400;0,600;0,800;1,200;1,400;1,600;1,800&family=Roboto+Mono&display=swap');

body {
  color: #505050;
  background-color: #001e57 !important;
}

.ant-btn-link, a, a:hover {
  color: #47abed;
}

#fifteen {
  .content-wrapper {
    background: #EEEEEE;
  }

  .ant-card {
    background: #FFFFFF;
  }  

  .ant-btn-primary {
    border-color: #1178ba;
    background: #1178ba;

    &[disabled] {
      opacity: .65;
    }
  }

  .ant-btn, .ant-input {
    border-radius: 5px;
  }

  tr.total-row {
    background-color: rgba(17, 120, 186, 0.9);
    color: white;
  }
}

h1.ant-typography, .ant-typography h1 {
  font-size: 23px;
}

@include media-breakpoint-up(sm) {
  h1.ant-typography, .ant-typography h1 {
    font-size: 25px;
  }  
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins';
}

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn span[role='img'] {
  vertical-align: inherit;
}

.ant-btn.secondary {
  background-color: #56A5CF;
  color: white;
  border: none;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #56A5CF;
  font-weight: bold;
  color: white;

  .anticon-warning {
    padding-top: 5px;
    margin-right: 5px;
  }
}

.ant-picker-input input {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0, 0, 0, 0.85);
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, 0.85);
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(0, 0, 0, 0.85);
  }  
}

.ant-picker-suffix {
  color: rgba(0, 0, 0, 0.85);
}

label.ant-segmented-item {
  margin-bottom: 0;
}

.ant-btn.blue-theme-btn {
  background: linear-gradient(#1679BB 0%, #001E57 100%) !important;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5rem !important;
  font-size: 18px;
  font-weight: bold;
  padding-top: 4.4px;
  border-color: #FFF !important;
}

.ant-card.theme-card {
  border-radius: 5px;
  color: white;
  font-weight: bold;
  border-radius: 1.5rem;
  border: solid 2px #FFFFFF !important;
  box-shadow: 3px 3px 8px rgba(31, 31, 31, 0.25);
  height: 100%;
  &.light-blue {
    background: linear-gradient(#D9FBFF 0%, #B6F4FC 100%) !important;
  }
  &.light-yellow {
    background: linear-gradient(#FFF4B5 0%, #FCD383 100%) !important;
  }
  h2 {
    color: #001E57;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
}

.exercise-suggestion-modal {
  .close-modal {
    margin-left: -20px;
    margin-top: -10px;
  }
  h2 {
    color: #FF7F00;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  h3 {
    color: #001E57;
    font-size: 20px;
  }
  .poster {
    border: solid 10px #FFFFFF;
    border-radius: 1rem;
    box-shadow: 3px 3px 8px rgba(31, 31, 31, 0.25);
  }
  p {
    color: #1679BB;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }
  .ant-modal-content {
    border-radius: 1rem;
  }
  .avatar {
    position: absolute;
    top: -185px;
    @media (min-width: 992px) {
      top: -30px;
      left: -185px;
    }
  }
  .ant-modal {
    top: 280px;
    @media (min-width: 992px) {
      top: 100px;
    }      
  }
}

.ant-btn.suggest-btn {
  background: linear-gradient(#47ABED 0%, #1679BB 100%) !important;
  text-align: center;
  border-radius: 5rem !important;
  font-size: 18px;
  font-weight: bold;
  padding-top: 4.4px;
  border-color: #FFF !important;
}

.ant-btn.no-thanks {
  color: #1679BB;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 18px;
  border-bottom: solid 1px #1679BB;
  line-height: 2.5;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.6);
}